<template>
  <div>
    <!--    <div id="visit_summary" class="row pt-5">-->
    <!--      <div class="container-public" ref="previewReceiptHtml" style="color: black">-->
    <div class="word-confirmation public-text-confirmation">
      <!--      <h1 class="public-text-confirmation">-->
      Your visit confirmation is received
      <!--      </h1>-->
      <br />
    </div>
    <div class="public-text-confirmation confirm-public">
      <b>Visitation Code</b> : <span>{{ visit_code }}</span>
      <br />
      <!--          <div>Please expect an email for visit details. Thank you.</div>-->
      <!--            Details about your visit will be sent to you via email. Thank you.-->
      <span>You will receive your visit details via email.</span>
      <div class="public-word">
        However, if you cannot find your email within the inbox, kindly check
        your "Junk Mail" or "Spam" folder.
      </div>
      Thank you.
    </div>
    <div class="button-confirmation">
      <b-button class="text-center" block variant="primary" @click="restart"
        >Done</b-button
      >
    </div>

    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="mb-5"></div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import jsPDF from "jspdf";

export default {
  data: function () {
    return {
      currentUserID: null,
      visit_code: "-"
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    // getPublicData() {
    //   return this.$store.state.public;
    // },
    getMasterVisitor() {
      let output = {
        email: "",
        id: "",
        passport_number: "",
        profile: {},
        is_blocklisted: false,
        created: "",
        name: "",
        nric_number: "",
        contact_number: "",
        face_image: "",
        additional_metadata: {},
        modified: ""
      };

      if (this.getPublicData.visitors.length > 0) {
        return {
          ...output,
          ...this.getPublicData.visitors[0]
        };
      } else {
        return {
          ...output
        };
      }
    }
  },
  created() {
    this.visit_code = this.$route.query.visit_code
      ? this.$route.query.visit_code
      : "-";
  },
  mounted: function () {},
  methods: {
    dateTimeFormatter(time) {
      return moment(time, "HH:mm:ss").format("LT");
    },
    //format data
    displayDataFormatter(data) {
      if (data === "" || data == null) {
        return "-";
      } else {
        return data;
      }
    },
    printPdf() {
      var doc = new jsPDF("p", "pt", "A4");
      let margins = {
        top: 80,
        bottom: 60,
        left: 40,
        width: 522
      };

      doc.setTextColor(100);

      doc.html(this.$refs.previewReceiptHtml, {
        callback: function (doc) {
          doc.autoPrint();
          doc.output("dataurlnewwindow");
        },
        x: 10,
        y: 10
      });
    },

    restart() {
      this.$bvModal
        .msgBoxConfirm(
          "Do you want to end your session and create another new visit?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          console.log("value", value);
          if (value) {
            document.location.href = "/public/registration";
          }
        })
        .catch((err) => {
          // An error occurred
        });
    }
  }
};
</script>
<style lang="scss" scope>
.word-confirmation {
  word-wrap: break-word;
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  top: 20%;
  text-align: center;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}

.button-confirmation {
  position: absolute;
  top: 90%;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}
.confirm-public {
  word-wrap: break-word;
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
  padding: 0rem 2rem;
  overflow: hidden;
  font-size: 18px;
}
.public-word {
  word-wrap: break-word;
}
</style>
